import { PrismicItemRichText } from '../types/prismic';

// TODO: loop all items, not only first one
export const richTextAsString = (content: PrismicItemRichText[] | { type: 'embed' }[] | undefined): string | undefined => {
  if (
    content
    && content.length > 0
    && 'text' in content[0]
    && content[0].text
    && content[0].text.trim() !== ''
  ) return content[0].text;
  return undefined;
};
