// import { Item as PhotoSwipeItem } from 'photoswipe';
import Imgix, { buildURL } from 'react-imgix';

// import { PrismicSliceImage, PrismicSliceImageGallery } from '../types/prismic';
// import { richTextAsString } from './rich-text-as-string';

export const buildImgixUrl = (baseUrl: string, params: Imgix['props']['imgixParams']): string => (
  buildURL(baseUrl, {
    auto: 'format,compress',
    ...params,
  })
);

export const buildImgixUrlWithAspectRatio = (
  baseUrl: string,
  aspectRatio: number,
  maxWidth: number,
  extraParams?: Imgix['props']['imgixParams'],
): string => (
  buildImgixUrl(baseUrl, {
    // TODO: use cropRect?
    'max-w': maxWidth,
    'max-h': (maxWidth / aspectRatio).toFixed(0),
    fit: 'crop',
    crop: 'faces',
    ar: aspectRatio.toFixed(2),
    trim: 'auto',
    ...extraParams,
  })
);

interface ImageItem {
  baseSrc: string;
  src: string;
  width: number;
  height: number;
  caption?: string;
  description?: string;
}

// export const getImageItemsFromSlice = (
//   imageSlice: PrismicSliceImage | PrismicSliceImageGallery,
//   options?: {
//     inlineImgixParams?: Imgix['props']['imgixParams'];
//     galleryImgixParams?: Imgix['props']['imgixParams'];
//   },
// ): { inlineImageItems: ImageItem[]; galleryImageItems: PhotoSwipeItem[] } => {
//   const images: (Omit<ImageItem, 'src'> & { baseSrc: string })[] = [];

//   if (imageSlice.slice_type === 'image') {
//     imageSlice.items.forEach((imageItem) => {
//       images.push({
//         baseSrc: imageItem.image.url.split('?')[0],
//         width: imageItem.image.dimensions.width,
//         height: imageItem.image.dimensions.height,
//         caption: richTextAsString(imageItem.image_caption),
//         description: richTextAsString(imageItem.image_description),
//       });
//     });
//   } else {
//     imageSlice.items.forEach((imageItem) => {
//       images.push({
//         baseSrc: imageItem.gallery_image.url.split('?')[0],
//         width: imageItem.gallery_image.dimensions.width,
//         height: imageItem.gallery_image.dimensions.height,
//         caption: richTextAsString(imageItem.gallery_image_caption),
//         description: richTextAsString(imageItem.gallery_image_description),
//       });
//     });
//   }

//   return ({
//     inlineImageItems: images.map((image) => ({
//       ...image,
//       src: buildImgixUrl(image.baseSrc, options?.inlineImgixParams),
//     })),

//     galleryImageItems: images.map((image) => ({
//       src: buildImgixUrl(image.baseSrc, options?.galleryImgixParams),
//       w: image.width,
//       h: image.height,
//       title: image.description,
//     })),
//   });
// };

export const scaledImageDimensions = (
  minWidth: number | null,
  maxWidth: number,
  width: number,
  height: number,
): { width: number; height: number } => {
  let scaledWidth = width;
  if (minWidth && width < minWidth) { scaledWidth = minWidth; }
  if (width > maxWidth) scaledWidth = maxWidth;

  const scaledHeight = (scaledWidth * height) / width;

  return { width: scaledWidth, height: scaledHeight };
};
