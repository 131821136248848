import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';
import { FaInstagram, FaTiktok } from 'react-icons/fa';

import './Footer.scss';

const Footer: React.FC = () => (
  <footer>
    <ul className="footer-social">
      <li>
        <OutboundLink href="https://instagram.com/experienciasincriveis" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
          Instagram
        </OutboundLink>
      </li>

      <li>
        <OutboundLink href="https://www.tiktok.com/@experiencias_incriveis" target="_blank" rel="noopener noreferrer">
          <FaTiktok />
          TikTok
        </OutboundLink>
      </li>
    </ul>

    <ul className="footer-links">
      <li>
        <Link to="/">
          Home
        </Link>
      </li>

      <li>
        <Link to="/experiencias/">
          Experiências Incríveis
        </Link>
      </li>

      <li>
        <Link to="/contato/">
          Contato
        </Link>
      </li>
    </ul>
  </footer>
);

export default Footer;
