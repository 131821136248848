import { Link } from 'gatsby';
import React from 'react';
import { FaChevronRight, FaHome } from 'react-icons/fa';

import './Nav.scss';

const Nav: React.FC = () => (
  <nav>
    <div className="container">
      <ul>
        <li>
          <Link to="/" title="Home">
            <FaHome />
          </Link>
        </li>

        <li>
          <Link to="/viagem/">
            Viagem
          </Link>
        </li>

        <li>
          <Link to="/cabanas/">
            Cabanas
          </Link>
        </li>

        <li>
          <Link to="/natureza/">
            Natureza
          </Link>
        </li>

        <li>
          <Link to="/adrenalina/">
            Adrenalina
          </Link>
        </li>

        <li>
          <Link to="/luxo/">
            Luxo
          </Link>
        </li>

        <li>
          <Link to="/bem-estar/">
            Bem-estar
          </Link>
        </li>

        <li>
          <Link to="/restaurantes/">
            Restaurantes
          </Link>
        </li>

        <li>
          <Link to="/diversao/">
            Diversão
          </Link>
        </li>

        <li>
          <Link to="/experiencias/">
            Mais Experiências
            <FaChevronRight />
          </Link>
        </li>
      </ul>
    </div>
  </nav>
);

export default Nav;
