import React from 'react';

const Img: React.FC<{
  src: string;
  alt?: string;
}> = ({ src, alt }) => (
  <img className="img" src={src} alt={alt} loading="lazy" />
);

export default Img;
