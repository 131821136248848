import { Link, navigate } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React, { useState } from 'react';
import { FaInstagram, FaSearch, FaTiktok } from 'react-icons/fa';

import LogoWhite from '../../assets/logo-w.svg';
import AspectBox from '../AspectBox';

import './Header.scss';

const HeaderSearch: React.FC<{
  searchQuery?: string;
  placeholder?: string;
  autoFocus?: boolean;
}> = ({ children, searchQuery, placeholder, autoFocus }) => {
  const [searchQueryValue, setSearchQueryValue] = useState<string>(searchQuery ?? '');

  const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (searchQueryValue && searchQueryValue.trim() !== '') {
      navigate(`/busca/?q=${encodeURIComponent(searchQueryValue)}`);
    }
  };

  return (
    <form
      action="/busca/"
      method="get"
      onSubmit={handleSearchSubmit}
    >
      {children}

      <input
        type="search"
        name="q"
        id="searchq"
        value={searchQueryValue}
        onChange={(event): void => setSearchQueryValue(event.target.value)}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
    </form>
  );
};

const Header: React.FC<{ searchQuery?: string }> = ({ searchQuery }) => {
  const [mobileSearchVisible, setMobileSearchVisible] = useState(false);

  return (
    <header>
      <div className="container">
        <div className="header-search">
          <HeaderSearch searchQuery={searchQuery}>
            <label htmlFor="searchq">
              <FaSearch />
            </label>
          </HeaderSearch>
        </div>

        <button
          type="button"
          className="header-search-mobile-toggle"
          onClick={(): void => setMobileSearchVisible(!mobileSearchVisible)}
        >
          <FaSearch />
        </button>

        <div className="header-logo">
          <Link to="/">
            <AspectBox heightRatio={9}>
              <LogoWhite />
            </AspectBox>
          </Link>
        </div>

        <div className="header-social">
          <ul>
            <li>
              <OutboundLink href="https://instagram.com/experienciasincriveis" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
                Instagram
              </OutboundLink>
            </li>

            <li>
              <OutboundLink href="https://www.tiktok.com/@experiencias_incriveis" target="_blank" rel="noopener noreferrer">
                <FaTiktok />
                TikTok
              </OutboundLink>
            </li>
          </ul>
        </div>
      </div>

      {mobileSearchVisible && (
        <div className="header-search-mobile">
          <HeaderSearch placeholder="Buscar" autoFocus />
        </div>
      )}
    </header>
  );
};

export default Header;
