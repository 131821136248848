import React from 'react';

import './AspectBox.scss';

const roundedPercentage = (number: number): string => {
  if (Number.isInteger(number)) return `${number}%`;
  return `${number.toFixed(2)}%`;
};

const AspectBox: React.FC<{
  heightRatio: number;
}> = ({ children, heightRatio }) => (
  <div className="aspect-box">
    <div
      className="aspect-box-inner"
      style={{ paddingBottom: roundedPercentage(100 / heightRatio) }}
    >
      {children}
    </div>
  </div>
);

export default AspectBox;
