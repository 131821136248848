import React from 'react';
import { Helmet } from 'react-helmet';

import { PrismicItemRichText } from '../../types/prismic';
import { buildImgixUrlWithAspectRatio } from '../../util/images';
import { richTextAsString } from '../../util/rich-text-as-string';
import Footer from '../Footer';
import Header from '../Header';
import Nav from '../Nav';

import '../../css/global.scss';

const parseMetaDescription = (description: string | PrismicItemRichText[]): string => {
  if (typeof description === 'string') return description;

  let metaDescription = '';

  description.forEach((item) => {
    if (metaDescription !== '') metaDescription += ' ';

    metaDescription += richTextAsString([item]);
  });

  if (metaDescription.length <= 160) return metaDescription;
  return metaDescription.slice(0, 157) + '...';
};

const parseMetaImage = (image: string): string => buildImgixUrlWithAspectRatio(image, 1.91 / 1, 1600);

const defaultMetaDescription = 'Saia da Inércia! Experiências incríveis pra quem quer sair da rotina, se desafiar e conhecer o inesperado.';
const defaultMetaImage = '/ogimg.png';

const Layout: React.FC<{
  mainClassName?: string;
  title?: string;
  searchQuery?: string;
  meta?: {
    description?: string | PrismicItemRichText[];
    image?: string;
  };
}> = ({ children, mainClassName, title, searchQuery, meta }) => {
  let metaDescription = defaultMetaDescription;
  let metaImage = defaultMetaImage;

  if (meta && meta.description) metaDescription = parseMetaDescription(meta.description);
  if (meta && meta.image) metaImage = parseMetaImage(meta.image);

  return (
    <div id="root">
      <Helmet>
        <html lang="pt-BR" />
        <title>{title ?? 'Experiências Incríveis'}</title>
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,800&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap" rel="stylesheet" />

        {metaDescription && <meta name="description" content={metaDescription} />}

        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />
      </Helmet>

      <Header searchQuery={searchQuery} />
      <Nav />

      <main className={mainClassName}>
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
